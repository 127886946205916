import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { Product } from 'helpers/productHelpers';
import { CsModal } from 'types/contentStack';
import {
  FeatureContainer,
  IconContainer,
  InlineDiv,
  ParagraphSpan,
  ParagraphSpanSemiBold,
  StyledButtonLink,
} from './styles';

type ProductFeatureProps = {
  includedWithProducts: Product[];
  modal: [] | [CsModal];
  featureText: string;
  product: Product;
  setModalId: React.Dispatch<React.SetStateAction<string | null>>;
};

const ProductFeature: React.FC<ProductFeatureProps> = ({
  includedWithProducts,
  modal,
  product,
  featureText,
  setModalId,
}) => {
  const optionalModal = unwrapSingleton(modal);
  // text between two pipe symbols is used as a modal button
  const featureTexts = featureText.split('|');

  return (
    <FeatureContainer>
      <IconContainer>
        {includedWithProducts.includes(product) ? (
          <Icon
            name="included"
            aria-label="Included"
            size="medium"
            color={colors.core01}
          />
        ) : (
          <Icon
            name="not-included"
            aria-label="Not included"
            size="medium"
            color={colors.core01}
          />
        )}
      </IconContainer>
      {featureTexts.length === 1 && (
        <ParagraphSpanSemiBold>{featureTexts[0]}</ParagraphSpanSemiBold>
      )}
      {featureTexts.length > 1 && (
        <span>
          <ParagraphSpan>{featureTexts[0]}</ParagraphSpan>
          <InlineDiv>
            {featureTexts[1] &&
            optionalModal &&
            includedWithProducts.includes(product) ? (
              <StyledButtonLink
                onClick={() => {
                  setModalId(optionalModal.modal_id);
                }}>
                {featureTexts[1]}
              </StyledButtonLink>
            ) : (
              <ParagraphSpanSemiBold>{featureTexts[1]}</ParagraphSpanSemiBold>
            )}
          </InlineDiv>
          {featureTexts[2] && includedWithProducts.includes(product) && (
            <ParagraphSpan>{featureTexts[2]}</ParagraphSpan>
          )}
        </span>
      )}
    </FeatureContainer>
  );
};

export default ProductFeature;

import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled, { css } from 'styled-components';

export const summaryOfCoverBodyLateralPaddings = css`
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    padding-left: ${spacing(3)};
    padding-right: ${spacing(3)};
  `};

  ${mediaQuery.tabletLandscape`
    padding-left: ${spacing(12)};
    padding-right: ${spacing(12)};
  `};
`;

export const BodyPartTitle = styled.p`
  background-color: ${colors.neutral05};
  ${fonts.headingXSmall};

  padding-top: ${spacing(2)};
  padding-bottom: ${spacing(2)};
  ${summaryOfCoverBodyLateralPaddings};
  margin: 0;
`;

export const StyledSpan = styled.span`
  & {
    font-weight: ${semiBoldFontWeight};
  }
`;

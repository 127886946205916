import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React, { useState } from 'react';
import CustomModal from 'components/CustomModal';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { Product } from 'helpers/productHelpers';
import ProductFeatureComponent from './ProductFeature';
import { ProductFeature } from './content';
import { ProductFeatureListItem, ProductFeaturesTableContainer } from './styles';

export enum PetType {
  AllCases = 'All cases',
  CatsAndDogs = 'Cats and Dogs',
  CatsOnly = 'Cats only',
  DogsOnly = 'Dogs only',
}

type ProductFeaturesTableProps = {
  features: ProductFeature[];
  product: Product;
  pageTitle: PageTitle;
  columnsPerRow?: number;
} & ComponentProps;

const ProductFeaturesTable: React.FC<ProductFeaturesTableProps> = ({
  pageTitle,
  product,
  features,
  columnsPerRow,
  ...props
}) => {
  const [modalId, setModalId] = useState<string | null>(null);

  return (
    <ProductFeaturesTableContainer {...componentProps(props)}>
      <CustomModal
        modalId={modalId}
        onClose={() => setModalId(null)}
        trackRichTextLinkClick={trackRichTextLinkClick(pageTitle)}
        pageTitle={pageTitle}
      />
      {features.map((feature) => {
        return (
          <ProductFeatureListItem
            key={feature.feature_text}
            columnsPerRow={columnsPerRow ?? 1}>
            <ProductFeatureComponent
              includedWithProducts={
                feature.included_with_products_selector.product_selector
              }
              modal={feature.modal}
              product={product}
              featureText={feature.feature_text}
              setModalId={setModalId}
            />
          </ProductFeatureListItem>
        );
      })}
    </ProductFeaturesTableContainer>
  );
};

export default ProductFeaturesTable;

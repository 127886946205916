import { graphql, useStaticQuery } from 'gatsby';
import { CsApplicableProducts, CsModal } from 'types/contentStack';

export type ProductFeature = {
  display_on_products_selector: CsApplicableProducts;
  included_with_products_selector: CsApplicableProducts;
  applicable_pets: string[];
  modal: [] | [CsModal];
  feature_text: string;
};

export type CsProductFeaturesTable = {
  csPetSummaryOfCover: {
    features_info: {
      product_feature: ProductFeature[];
    };
  };
};

const query = graphql`
  query {
    csPetSummaryOfCover {
      features_info {
        product_feature {
          display_on_products_selector {
            product_selector
          }
          included_with_products_selector {
            product_selector
          }
          applicable_pets
          modal {
            modal_id
          }
          feature_text
        }
      }
    }
  }
`;

const useProductFeatureTableContent = (): CsProductFeaturesTable =>
  useStaticQuery<CsProductFeaturesTable>(query);

export default useProductFeatureTableContent;

import { MaxWidthAndSpacingContainer } from '@rsa-digital/evo-shared-components/components/GridContainer/styles';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import ScreenReaderAnnouncer, {
  useScreenReaderAnnouncement,
} from '@rsa-digital/evo-shared-components/components/ScreenReaderAnnouncer';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle, trackSwitchPaymentClick } from 'helpers/eventTracking';
import { getVetFeeLimit } from 'helpers/getVetFeeLimit';
import {
  CoverPercentagePlaceholderData,
  coverPercentagePlaceholders,
} from 'helpers/placeholders/coverPercentagePlaceholders';
import { getQuotePlaceholdersWithProductOption } from 'helpers/placeholders/quotePlaceholders';
import {
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import {
  formatInPoundsWithSign,
  formatStringInPoundsWithSign,
} from 'helpers/poundsHelpers';
import { Product } from 'helpers/productHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import {
  ButtonWrapper,
  MarginalContainer,
  MarginalTitle,
  MonthlyPaymentReminder,
  ParagraphLightWithMargin,
  ParagraphLightWithMarginTop,
  PaymentAmount,
  PaymentSchedule,
  PaymentSimplePanel,
  PayMonthlyPromoHeadingWrapper,
  PayMonthlyPromotion,
  StyledFlexibleWidthDivider,
  StyledRichText,
  SwitchPaymentButton,
} from './styles';
import usePricingInfoContent, { getPricingInfoPerProduct } from '../PricingInfo/content';
import usePercentageOfClaimsCovered from '../PricingInfo/usePercentageOfClaimsCovered';

type CsHeaderRow = {
  heading: string;
  essential_cover_banner_text: string;
  essential_cover_subheading_text: string;
  per_month_text: string;
  per_month_link_text: string;
  per_year_text: string;
  per_year_link_text: string;
  monthly_payment_reminder_text: string;
};

type CsSummaryOfCoverMarginal = {
  csPetSummaryOfCover: {
    header_row: CsHeaderRow;
  };
  csPetSummaryOfCoverInstallmentInfo: {
    annual_installment_text: string;
    monthly_installment_text: string;
  };
  csPetAnnualPayment: {
    payment_summary: {
      pay_monthly_promo_heading: string;
      pay_monthly_promo_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetSummaryOfCover {
      header_row {
        heading
        essential_cover_banner_text
        essential_cover_subheading_text
        per_month_link_text
        per_year_link_text
        monthly_payment_reminder_text
      }
    }
    csPetSummaryOfCoverInstallmentInfo {
      annual_installment_text
      monthly_installment_text
    }
    csPetAnnualPayment {
      payment_summary {
        pay_monthly_promo_heading
        pay_monthly_promo_text
      }
    }
  }
`;

type SummaryOfCoverMarginalProps = {
  product: Product;
};

const SummaryOfCoverMarginal: React.FC<SummaryOfCoverMarginalProps> = ({ product }) => {
  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const {
    csPetSummaryOfCover: {
      header_row: {
        heading,
        essential_cover_banner_text,
        essential_cover_subheading_text,
        per_month_link_text,
        per_year_link_text,
        monthly_payment_reminder_text,
      },
    },
    csPetSummaryOfCoverInstallmentInfo: {
      annual_installment_text,
      monthly_installment_text,
    },
    csPetAnnualPayment: {
      payment_summary: { pay_monthly_promo_heading, pay_monthly_promo_text },
    },
  } = useStaticQuery<CsSummaryOfCoverMarginal>(query);
  const [announcementText, makeAnnouncement] = useScreenReaderAnnouncement();

  const getFormattedProductPrice = (isAnnualPayment: boolean): string => {
    const productPrices = quote.price;
    return formatInPoundsWithSign(
      isAnnualPayment
        ? productPrices?.annualPrice.total
        : productPrices?.monthlyPrice.total
    );
  };

  const announcePaymentSwitch = (isAnnualPayment: boolean): void => {
    const switchPaymentScreenReaderMessage = isAnnualPayment
      ? `Switching to an annual payment of ${getFormattedProductPrice(isAnnualPayment)}`
      : `Switching to 12 monthly payments of ${getFormattedProductPrice(
          isAnnualPayment
        )}`;
    makeAnnouncement(switchPaymentScreenReaderMessage);
  };

  const paymentOnClick = (): void => {
    trackSwitchPaymentClick(
      PageTitle.QuoteSummary,
      quote.quoteOptions.isAnnualPayment ? 'monthly' : 'annually'
    );
    const updatedIsAnnualPayment = !quote.quoteOptions.isAnnualPayment;
    updateQuoteOptions({
      isAnnualPayment: updatedIsAnnualPayment,
    });
    announcePaymentSwitch(updatedIsAnnualPayment);
  };

  const replaceProductPlaceholders = replacePlaceholdersPlainText(
    getQuotePlaceholdersWithProductOption(product),
    quote
  );
  const pricingInfoContent = usePricingInfoContent();

  const pricingInfoPerSelectedProduct = getPricingInfoPerProduct(
    pricingInfoContent,
    product
  );
  const coverPercentage = usePercentageOfClaimsCovered();
  const vetFeeLimit = getVetFeeLimit(quote);
  const coverPlaceholderData: CoverPercentagePlaceholderData = {
    coveredClaimsPercentage: coverPercentage,
    coverLimit: vetFeeLimit ? formatStringInPoundsWithSign(vetFeeLimit) : '',
    quote,
  };
  const substituteCoverPercentagePlaceholders = replacePlaceholdersRichText(
    coverPercentagePlaceholders,
    coverPlaceholderData
  );
  const getProduct = (): boolean => {
    switch (product) {
      case Product.Essential:
        return false;
      case Product.Standard:
        return true;
      case Product.Plus:
        return true;
      case Product.Premier:
        return true;
      default:
        return false;
    }
  };

  const productStandardOrPlusOrPremier = getProduct();

  return (
    <>
      <MarginalContainer data-cy="summary-of-cover-marginal">
        <ScreenReaderAnnouncer announcementText={announcementText} />
        <MarginalTitle>{replaceProductPlaceholders(heading)}</MarginalTitle>
        {product === Product.Essential && (
          <>
            <ParagraphLightWithMargin>
              <Icon name="information" size="medium" />
              <StyledRichText
                data-cy="payMonthlyPromoText"
                html={essential_cover_banner_text}
              />
            </ParagraphLightWithMargin>
            <StyledFlexibleWidthDivider />
          </>
        )}
        {productStandardOrPlusOrPremier && (
          <>
            <ParagraphLightWithMargin>
              <Icon name="information" size="medium" />
              <StyledRichText
                data-cy="payMonthlyPromoText"
                html={substituteCoverPercentagePlaceholders(
                  pricingInfoPerSelectedProduct.typical_treatment_costs.banner_text
                )}
              />
            </ParagraphLightWithMargin>
            <StyledFlexibleWidthDivider />
          </>
        )}
        <PaymentSimplePanel data-cy="price-panel">
          <PaymentSchedule>
            {quote.quoteOptions.isAnnualPayment
              ? annual_installment_text
              : monthly_installment_text}
          </PaymentSchedule>
          <PaymentAmount>
            {getFormattedProductPrice(quote.quoteOptions.isAnnualPayment)}
          </PaymentAmount>
          <MonthlyPaymentReminder>{monthly_payment_reminder_text}</MonthlyPaymentReminder>
          <ButtonWrapper>
            <SwitchPaymentButton onClick={() => paymentOnClick()}>
              {quote.quoteOptions.isAnnualPayment
                ? `${replaceProductPlaceholders(per_year_link_text)}`
                : `${replaceProductPlaceholders(per_month_link_text)}`}
            </SwitchPaymentButton>
          </ButtonWrapper>
          {quote.quoteOptions.isAnnualPayment && (
            <PayMonthlyPromotion data-cy="pay-monthly-promotion-on-quote-summary">
              <StyledFlexibleWidthDivider />
              <PayMonthlyPromoHeadingWrapper>
                <Icon name="information" size="medium" />
                <span data-cy="payMonthlyPromoHeading">{pay_monthly_promo_heading}</span>
              </PayMonthlyPromoHeadingWrapper>
              <StyledRichText
                data-cy="payMonthlyPromoText"
                html={pay_monthly_promo_text}
              />
            </PayMonthlyPromotion>
          )}
        </PaymentSimplePanel>
      </MarginalContainer>
      {product === Product.Essential && (
        <ParagraphLightWithMarginTop>
          <MaxWidthAndSpacingContainer>
            {essential_cover_subheading_text}
          </MaxWidthAndSpacingContainer>
        </ParagraphLightWithMarginTop>
      )}
      {productStandardOrPlusOrPremier && (
        <ParagraphLightWithMarginTop>
          <MaxWidthAndSpacingContainer>
            <StyledRichText
              html={substituteCoverPercentagePlaceholders(
                pricingInfoPerSelectedProduct.typical_treatment_costs
                  .description_rich_text
              )}
              data-cy="summary-of-cover-typical-treatment-costs-rich-text"
            />
          </MaxWidthAndSpacingContainer>
        </ParagraphLightWithMarginTop>
      )}
    </>
  );
};

export default SummaryOfCoverMarginal;

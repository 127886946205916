import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { fonts } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled, { css } from 'styled-components';
import { summaryOfCoverBodyLateralPaddings } from '../SummaryOfCover/SummaryOfCoverBodyPart/styles';

export const ProductFeaturesTableContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;

  text-align: left;
  list-style-type: none;
  margin: 0;

  ${summaryOfCoverBodyLateralPaddings};

  padding-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    padding-top: ${spacing(4)};
  `};
`;

export const ProductFeatureListItem = styled.li<{ columnsPerRow: number }>`
  display: flex;
  width: 100%;
  ${mediaQuery.tabletPortrait`
    ${({ columnsPerRow }) =>
      css`
        width: calc(${100 / columnsPerRow}%);
      `}
  `};

  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `};
  span {
    ${fonts.paragraph}
  }
`;

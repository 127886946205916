import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const FeatureContainer = styled.div`
  display: flex;
  align-items: flex-start;
  page-break-inside: avoid;
`;

export const IconWithMargins = styled(Icon)`
  margin-right: ${spacing(3)};
`;

export const StyledButtonLink = styled(ButtonLink)`
  ${fonts.paragraph}

  ${mediaQuery.tabletPortrait`
    && {
      ${fonts.paragraphLarge};
    }
  `};

  && {
    color: ${colors.core01};
    font-weight: ${semiBoldFontWeight};
  }
  text-decoration: underline;
  text-align: left;
  display: inline;
`;

export const ParagraphSpan = styled.span`
  ${fonts.paragraph};

  ${mediaQuery.tabletPortrait`
    ${fonts.paragraphLarge};
  `};

  margin: 0;
`;

export const ParagraphSpanSemiBold = styled(ParagraphSpan)`
  && {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const InlineContainer = styled.div`
  ${fonts.paragraph};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spacing(2)};
  margin-top: ${spacing(0.5)};
`;

export const InlineDiv = styled.div`
  display: inline;
  padding: 0;
  margin: 0;
`;
